.ui.fluid.card{
    padding: 0.5rem 0.5rem;
    min-width: 50rem;

}

.image{
    max-width: fit-content;
}

.ui.card>.image>img,
.ui.cards>.card>.image>img {
    object-fit: scale-down;
    max-height: 220px;
    max-width: 100%;
    width:unset;
}

.ui.card {
    margin:1em 0;
    min-width: 18rem;
   }

.description {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.ui.card>.extra,
.ui.cards>.card>.extra {
    border-top: 0px solid rgba(0,0,0,.05) !important;
    margin-top: auto;
}

.ui.card>.content>.description,
.ui.cards>.card>.content>.description {
 color:rgba(0,0,0,.87);
}