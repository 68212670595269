.circular.ui.yellow.icon.button{
    margin-left: 0.75rem;
    display: inline-block;
    vertical-align: baseline;
}

h2:last-child{
    float: left;
}

.ui.form{
  text-align: left;
  padding-right: 0rem;
}

.ui.form .field{
  margin-bottom: 0rem;
}

.ui.form input::placeholder { 
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:  rgba(0,0,0,.55);
  opacity: 1; /* Firefox */
}

.ui.form input:focus::placeholder {
    color:  rgba(0,0,0,.8);
    opacity: 1; /* Firefox */
} 

.ui.form input:not([type]), 
.ui.form input[type="text"], 
.ui.form input[type="url"] {
    border: 1px solid rgba(0,0,0,.5);
}

.ui.form input:not([type]):focus, 
.ui.form input[type="text"]:focus, 
.ui.form input[type="url"]:focus {
    color: rgba(0,0,0,.95);
    border-color: #fbbd08;
    border: 1px solid rgba(0,0,0,.5);
    border-radius: .28571429rem;
    background: #fff;
    box-shadow: 0 0 0 0 rgba(34,36,38,.35) inset;
}

.ui.form input:not([type]),
.ui.form input[type=text],
.ui.form input[type=url] {
 font-family:Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
 margin:0;
 outline:0;
 appearance:none;
 line-height:1.21428571em;
 padding:.67857143em 1em;
 font-size:1em;
 background:#fff;
 border:1px solid rgba(0,0,0,.2);
 color:rgba(0,0,0,.87);
 border-radius:.28571429rem;
 box-shadow:0 0 0 0 transparent inset;
 transition:color .1s ease,border-color .1s ease;
 display: inline-block;
 vertical-align: baseline;
}

.react-autosuggest__container {
    position: relative;
    /* display: inline-block; */
    border-style: solid;
    border-width: 2px;
    border-radius: 6px;
    padding: 4px 4px 4px 8px;
    border-color: rgba(0, 0, 0, 0.6);
  }
  
  .react-autosuggest__input {
    width: 240px;
    height: 3em;
    padding: .67857143em 1em;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 1em;
    border: 1px solid #aaa;
    border-radius: 4px;
    display: inline-block;
  }
  
  .react-autosuggest__input:focus {
    outline: none;
  }
  
  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 51px;
    width: 98%;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    text-align: left;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    margin-top: 0.5rem;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
  }
  
  .react-autosuggest__suggestion--focused {
    background-color: #fbbd08;
    color: #fff;
  }
  
  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }
  
  .name {
    /* margin-left: 68px;*/
    /* line-height: 45px*/
    line-height: normal;
  }
  
  .highlight {
    color:  #2f2e41;
    font-weight: bold;
  }
  
  .react-autosuggest__suggestion--focused .highlight {
    color: #120000;
  }

  .ui.action.input:not([class*="left action"])>input:focus {
    border-right-color: transparent !important;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  #cancelsearchterm {
    background-color: transparent;
    padding-right: 1.1rem;
  }

  .ui.checkbox {
    font-weight:600;
    margin-bottom: 0.5rem;
  }