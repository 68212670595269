.ui.yellow.label {
    margin-left: 0.3rem;
    color:  #2f2e41 !important;
}


.filterbutton {
    border-radius: 5px;
    cursor: pointer;
}

@media screen and (max-width: 468px) {
    #desktop-only {display: none;}
    }